
<template>
  <div class="installPrompt" v-if="isPluginInstalled">
    <div class="installPromptBox">
      <div class="closeBtn" @click="closePromptClick">
        <img :src="require('@/assets/icon/closeWhite.png')" alt="" />
      </div>
      <div class="conBox">
        <img class="RAGlogoImg" :src="require('@/assets/icon/rakumartandGoogle.svg')" alt="" />
        <span>Good news: We have just launched an extension for Google Chrome 🎉</span>
        <a href="https://chrome.google.com/webstore/detail/find-the-same-product-on/bhkckfiicihkphdjpibpmmohcjkjflfg?utm_source=ext_sidebar&hl=zh-CN"
          target="_blank" class="toGooGleShopBtn">
          <svg version="1.1" class="linkIcon" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 194.818 194.818"
            style="enable-background: new 0 0 194.818 194.818" xml:space="preserve">
            <g>
              <path d="M185.818,2.161h-57.04c-4.971,0-9,4.029-9,9s4.029,9,9,9h35.312l-86.3,86.3c-3.515,3.515-3.515,9.213,0,12.728
		c1.758,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636l86.3-86.3v35.313c0,4.971,4.029,9,9,9s9-4.029,9-9v-57.04
		C194.818,6.19,190.789,2.161,185.818,2.161z" />
              <path d="M149,77.201c-4.971,0-9,4.029-9,9v88.456H18v-122h93.778c4.971,0,9-4.029,9-9s-4.029-9-9-9H9c-4.971,0-9,4.029-9,9v140
		c0,4.971,4.029,9,9,9h140c4.971,0,9-4.029,9-9V86.201C158,81.23,153.971,77.201,149,77.201z" />
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
          Install Chrome's extension
        </a>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showReminder: false,
      closePrompt: !!sessionStorage.getItem('closePrompt'),
    };
  },
  computed: {
    isPluginInstalled() {
      return (
        !document.getElementById('rakumart-plugin-dom') && !this.closePrompt
      )
    },
  },
  methods: {
    // 关闭推广
    closePromptClick() {

      this.closePrompt = true
      sessionStorage.setItem('closePrompt', true)
    },
  },
  watch: {
    isPluginInstalled: {
      handler(newValue, oldValue) {
        // 处理属性变化的逻辑
        this.$store.commit('editisPluginInstalled', newValue)
        this.$nextTick(() => {
          if (this.$store.state.isPluginInstalled == true) {
            document.getElementsByClassName("headMain")[0].style.height = "236px";
          } else {

            document.getElementsByClassName("headMain")[0].style.height = "186px";
          }
        })
      },
      deep: true, // 开启深度监听
      immediate: true, // 立即触发监听

    }
  }
  // editisPluginInstalled
};
</script>

<style lang="scss" scoped>
.installPrompt {
  $height: 54px;
  height: 48px;
  width: 100%;

  .installPromptBox {
    height: $height;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 110;
    width: 100%;
    height: 48px;
    background: linear-gradient(90deg, #608BFF 0%, #1BA37B 100%);

    .closeBtn {
      position: absolute;
      cursor: pointer;
      top: 15px;
      right: 50px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .conBox {
      width: 1400px;
      height: 48px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .RAGlogoImg {
        width: 73px;
        height: 30px;
        margin-right: 13px;
      }

      span {
        font-size: 14px;
        font-weight: 600;
        color: white;
        margin-right: 13px;
        margin-bottom: 2px;
      }

      .toGooGleShopBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 234px;
        height: 28px;
        background: #FFFFFF;
        border-radius: 4px;
        cursor: pointer;
        border-radius: 3px;
        width: 256px;
        height: 27px;
        font-size: 13px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #FF730B;
        line-height: 20px;
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        border-color: #ffffff;
        transition: 0.3s;

        .linkIcon {
          width: 13px;
          height: 13px;
          fill: #1a73e8;
          margin-right: 5px;
          font-weight: bold;
          transition: 0.3s;
        }

        &:hover {
          color: white;
          background-color: #FF730B;

          .linkIcon {
            fill: white;
          }
        }
      }


    }
  }
}
</style>
