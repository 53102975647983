<template>
  <div>
    <div class="headMain">
      <div id="HomePageTop">
        <!-- 谷歌插件提示 -->
        <GooglePluginReminder />
        <div class="booosdj">
          <div class="qiTa" id="qitasss">
            <div class="zhiNan">
              <span @click="$fun.toPage('/about-us')">
                <!-- 我们是谁 -->{{ $fanyi("关于RAKUMART") }}
              </span>
              <span @click="$fun.toPage('user-guide')">
                {{ $fanyi("用户指南") }}
              </span>
              <span @click="$fun.toPage('/frequently-asked-questions')">
                {{ $fanyi("常见问题解答") }}
              </span>
              <span @click="$fun.toPage('/commission')">
                {{ $fanyi("佣金") }}
              </span>
              <span @click="$fun.toPage('/international-freight')">
                {{ $fanyi("国际物流") }}
              </span>
              <span @click="$fun.toPage('/additional-services')">
                {{ $fanyi("附加服务") }}
              </span>
              <span @click="$fun.toPage('/video-maintenance')">
                {{ $fanyi("视频介绍") }}
              </span>
            </div>
            <div class="huiLv">
              {{ $fanyi("今日人民币与美元兑换汇率") }}:

              <font class="num numd">${{ $fun.huilvqianf($store.state.exchangeRate) }}</font>
              ≈
              <span class="numd">1￥</span>
            </div>
            <!-- 翻译 -->
            <el-dropdown @command="clickDropdown">
              <div class="China-ukhandover">
                <span class="chainse" :class="{ active: $store.state.languagetype == 'Chinese' }">
                  中</span>
                /
                <span class="english" :class="{ active: $store.state.languagetype == 'English' }">English</span>
              </div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="English">English</el-dropdown-item>
                <el-dropdown-item command="Chinese">Chinese</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div class="fgx"></div>
        <div id="HomePageTopCon">
          <div class="logoBox">
            <img id="logo" @click="backHome" src="../../assets/hometop/logo-rakumart-europe.svg" alt="" />
          </div>
          <div class="searchBoxDiv">
            <el-popover ref="popover" popper-class="popoversearchTip" placement="bottom" trigger="focus" width="722">
              <div class="Historicalrecord" v-if="keyWordList.length > 0">
                <div class="Historical">{{ $fanyi("历史记录") }}</div>

                <div class="record">
                  <div class="record-item" v-for="(Historyitem, index) in keyWordList" :key="index">
                    <span @click="Historicalsearch(Historyitem)">{{
                      Historyitem
                    }}</span>
                    <i @click.stop="deleteOldKeyWord(index)" class="el-icon-close"></i>
                  </div>
                </div>
              </div>

              <div class="searchitem" v-for="item in topKeywordlist" :key="item.id">
                <h2>{{ item.translate_name }}</h2>
                <div class="classification-item">
                  <span @click="Historicalsearch(childsitem.translate_name)" v-for="childsitem in item.childs"
                    :key="childsitem.id">
                    {{ childsitem.translate_name }}</span>
                </div>
              </div>
              <el-input style="width: 598px" slot="reference" @paste.native="handlePaste" @focus="focus"
                :readonly="readonly" v-model="$store.state.searchInfo" :placeholder="$fanyi('请输入关键词、1688商品链接或通过图片搜索')
                  " @keydown.enter.native="search" @change="$store.commit('getshoplistpage', 1)">
              </el-input>
            </el-popover>

            <el-upload ref="uploadRef" class="upImg" action="https://laravel.rakumart.us/api/common/uploadFile"
              list-type="picture-card" :before-upload="befoUpload" :on-success="successup" :show-file-list="false"
              accept=".jpg,.jpeg,.png">
              <img src="../../assets/icon/upload.svg" alt="" />
            </el-upload>
            <!-- <div class="searchInputBoxFg"></div> -->
            <el-button @click="search">
              <img src="../../assets/icon/search.svg" />
            </el-button>
          </div>

          <div class="operate">
            <div v-if="!token" class="userDetail notLogin">
              <img src="../../assets/icon/personal.svg" alt="" />
              <span>
                <span>
                  <!-- <router-link to="/login">{{ $fanyi("登录") }}</router-link> -->
                  <span @click="gologin">{{ $fanyi("登录") }}</span>
                </span>
                <span class="fg"> / </span>
                <span>
                  <router-link to="/register"><!--  -->{{ $fanyi("注册") }}</router-link>
                </span>
              </span>
            </div>
            <div v-else class="userDetail">
              <img class="userHeadImg" style="cursor: pointer" @click="$fun.tooPage('/user/index')" svg
                :src="userInfo ? userInfo.head_img : ''" />
              <div class="userLoginInfoBox">
                <p class="toUserIndex flexAndCenter">
                  Hello&nbsp;
                  <el-popover placement="top" trigger="hover">
                    <div>{{ userInfo.user_name }}</div>
                    <span class="user_name u-line toUserIndex" slot="reference"
                      style="font-size: 13px; padding-top: 4px">{{ userInfo.user_name ? userInfo.user_name : ""
                      }}</span>
                  </el-popover>
                </p>
                <p>
                  <span class="routerBtn" @click="
                    $fun.toPage('/user/index');
                  getUser();
                  ">
                    {{ $fanyi("个人中心") }}
                  </span>
                  <span style="font-size: 12px"> / </span>
                  <span class="routerBtn" @click="loginOut">{{ $fanyi("退出登录") }}
                  </span>
                </p>
              </div>
            </div>
            <!-- <div class="huilv">
              1￥ = <font class="num">{{ $store.state.exchangeRate }}</font
              >$
            </div> -->
            <div class="cartBtn" ref="end">
              <button type="button" @click="$fun.toPage('/cart')">
                <div class="carticon">
                  <img src="../../assets/icon/shopping_cart.svg" alt="" id="gouwuche" />
                  <span class="cartNum">{{ goodsNum }}</span>
                </div>
                {{ $fanyi("购物车") }}
              </button>
            </div>
          </div>
        </div>
        <div class="hr" v-if="showClassificationOfGoodsVue"></div>

        <sideBar />
      </div>
    </div>
  </div>
</template>

<script>
import ClassificationOfGoodsVue from "../homePage/ClassificationOfGoods.vue";
import router from "../../router";
import sideBar from "../homePage/sideBar.vue";
import GooglePluginReminder from "../../components/head/GooglePluginReminder.vue";
export default {
  data() {
    return {
      logininfo: localStorage.getItem("logininfo"),
      value: "",

      pageLoading: null,
      showQita: true, //显示其他
      readonly: true,
      visible: true,
      value: "",
      keyWordList: [], //历史记录
      topKeywordlist: [], // 配置搜索列表
    };
  },
  created() {
    // 防止搜索栏分类在跳转页面时因重新加载而清空
    this.value = this.$store.state.goodsListActiveId;

    this.$store.dispatch("goodsToCartNum");
    if (localStorage.getItem("clientUsFrontPCkeyWord") != null) {
      this.keyWordList = JSON.parse(
        localStorage.getItem("clientUsFrontPCkeyWord")
      );
    }
    this.$nextTick(() => {
      if (this.$store.state.isPluginInstalled == true) {
        document.getElementsByClassName("headMain")[0].style.height = "236px";
      } else {
        document.getElementsByClassName("headMain")[0].style.height = "186px";
      }
    });
    this.gettopKeyword();
    // this.getClassData();
  },
  components: {
    sideBar,
    ClassificationOfGoodsVue,
    GooglePluginReminder,
  },
  computed: {
    searchInfo() {
      return this.$store.state.searchInfo;
    },
    goodsNum() {
      return this.$store.state.cartCount;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    options() {
      return this.$store.state.goodsList;
    },
    token() {
      if (this.$store.state.userInfo) {
        return true;
      } else {
        return false;
      }
    },

    showClassificationOfGoodsVue() {
      if (["homepage"].indexOf(this.$route.name) != -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.changeHeight();
  },

  methods: {
    getUser() {
      this.$api.EuropegetUserInfo().then((res) => {
        this.$store.commit("userData", res.data);
      });
    },
    gologin() {
      this.$fun.toPage("/login");
    },
    deleteOldKeyWord(i) {
      this.keyWordList.splice(i, 1);
      localStorage.setItem(
        "clientUsFrontPCkeyWord",
        JSON.stringify(this.keyWordList)
      );
      this.keyWordList = JSON.parse(
        localStorage.getItem("clientUsFrontPCkeyWord")
      );
    },
    clickDropdown(value) {
      if (value != "") {
        this.$store.commit("Togglelanguagetype", value);
      }
    },
    // 禁止输入款自动填充
    focus() {
      this.readonly = false;
    },

    // 获取搜索框分类数据
    getClassData() {
      this.$api
        .getShopGroup()
        .then((result) => {
          this.options = result.data;
          this.options.unshift({
            chinese_name: "",
            translation_name: "Todos los",
            id: -1,
          });
        })
        .catch((err) => { });
    },
    handlePaste(event) {
      const items = (event.clipboardData || window.clipboardData).items;
      // 检查粘贴的项中是否有图片
      let hasImage = false;
      let hasText = false;
      for (let index in items) {
        const item = items[index];

        if (item.kind === "file" && item.type.indexOf("image/") !== -1) {
          // 读取图片文件
          const blob = item.getAsFile();
          hasImage = true;
          this.uploadFile(blob)
          break; // 假设我们只需要处理第一个图片
        } else if (item.kind === "string") {
          // 检查是否为文本
          hasText = true;
        }
      }
      if (!hasImage && !hasText) {
        this.$message.error(
          '请上传 image/jpeg", "image/png", "image/jpg 格式的图片'
        );
      }
    },
    uploadFile(file) {
      var url = "https://laravel.rakumart.us/api/common/uploadFile";
      const isJPG =
        ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;

      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传图片"));
        return isJPG;
      }

      this.pageLoading = this.$loading({
        lock: true,
        text: this.$fanyi("图片上传中"),
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, .7)",
      });
      var formData = new FormData();
      formData.append("file", file);

      var xhr = new XMLHttpRequest();

      xhr.open("POST", url, true);
      xhr.onload = () => {
        if (xhr.status === 200) {
          const res = JSON.parse(xhr.responseText);
          // 图搜
          this.pageLoading.close();
          this.$store.commit("getsearchImg", res.data);
          // 图搜统计
          this.addKeyWord(res.data);
          if (this.$fun.isOfficialWeb()) {
            __bl.sum("用户上传图片搜索数量统计");
          }
          if (this.$route.name == "CommoditySearch") {
            this.$fun.toPage(
              "/commoditySearch?type=imgSearch&imgUrl=" + res.data
            );
          } else {
            this.$fun.toPage(
              "/commoditySearch?type=imgSearch&imgUrl=" + res.data
            );
          }
        } else {
          console.log("An error occurred while uploading the file.");
        }
      };
      xhr.send(formData);
    },
    changeHeight() {
      var that = this;
      window.onscroll = function () {
        // if (router.history.current.fullPath == "/") {
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop >= 190) {
          document.getElementsByClassName("headMain")[0].style.height = "100px";
          document.getElementById("HomePageTopCon").style.height = "80px";
          document.getElementById("qitasss").style.height = "0";
          if (document.getElementById("mask")) {
            document.getElementById("mask").style.top = "150px";
          }
          // document.getElementById("classBox").style.display = "none";
        } else if (scrollTop <= 100) {
          if (that.$store.state.isPluginInstalled == true) {
            document.getElementsByClassName("headMain")[0].style.height =
              "236px";
          } else {
            document.getElementsByClassName("headMain")[0].style.height =
              "186px";
          }

          document.getElementById("HomePageTopCon").style.height = "100px";
          document.getElementById("qitasss").style.height = "59px";
          if (document.getElementById("mask")) {
            document.getElementById("mask").style.top = "220px";
          }
        }
        // 是否显示top键 (ps:topBtn不在这个页面里，在sideBar.vue里)

        if (scrollTop >= 500) {
          document.getElementById("topBtn").style.display = "block";
        } else if (scrollTop <= 530) {
          document.getElementById("topBtn").style.display = "none";
        }
        // }
      };
    },
    async gettopKeyword() {
      const res = await this.$api.topKeyword();
      if (res.code != 0) return;
      this.topKeywordlist = res.data;
    },
    // 返回首页
    backHome() {
      this.$fun.toPage("/");
      this.$store.commit("getsearchInfo", "");
    },
    // 跳转搜索页
    search() {

      // 判断是否是链接,如果是链接就跳转到相应页面
      let regtaobao = RegExp(/taobao/);
      let reg1688 = RegExp(/1688./);
      let regtmall = RegExp(/tmall/);
      let regrakumart = RegExp(/goods_id/);
      let regrakumartbr = RegExp(/iid/);
      let id = "";
      var reg1 = /1688.com\/offer\/([0-9]+)/;
      var reg2 = /id=(\d+)/;
      var reg3 = /[?|&]goods_id=(\d{10,15})/;
      var reg4 = /m\.1688\.com.*offerId=([0-9]{10,})/; //1688手机
      var reg5 = /tk=([^&\s]+)/; //taobao手机链接
      var reg6 = /[?|&]iid=(\d{10,15})/;

      // 判断是哪个网站的商品链接
      if (regtaobao.test(this.$store.state.searchInfo)) {
        // 淘宝进入商品详情页
        id = this.$store.state.searchInfo.match(reg2)[1];
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('用户通过淘宝链接搜索进入详情数量统计');
        }

        this.$fun.toCommodityDetails({ shop_type: "taobao", goods_id: id, type: 'taobaolinkSearch' });
        return false;
      } else if (reg1688.test(this.$store.state.searchInfo)) {
        var substring = "detail.1688.com";
        // 转换为小写后进行比较
        if (this.$store.state.searchInfo.toLowerCase().includes(substring.toLowerCase())) {
          if (this.$store.state.searchInfo.match(reg1) == null) {
            id = this.$store.state.searchInfo.match(reg4)[1];
          } else {
            id = this.$store.state.searchInfo.match(reg1)[1];
          }
          // 1688进入商品详情页
          if (this.$fun.isOfficialWeb()) {
            __bl.sum('用户通过1688链接搜索进入详情数量统计');
          }
          this.$fun.toCommodityDetails({ shop_type: "1688", goods_id: id, type: '1688linkSearch' });
          return false;
        } else {
          // // // 说明可能是店铺
          // // console.log("字符串中不包含 'detail.1688.com'");
          // // 跳转
          // this.$fun.routerToPage("/commoditysearch?keyword=" + this.$store.state.searchInfo, true);
        }



      } else if (regtmall.test(this.$store.state.searchInfo)) {
        id = this.$store.state.searchInfo.match(reg2)[1];
        // 天猫链接进入商品详情

        if (this.$fun.isOfficialWeb()) {
          __bl.sum('用户通过天猫链接搜索进入详情数量统计');
        }
        this.$fun.toCommodityDetails({ shop_type: "taobao", goods_id: id, type: 'tmalllinkSearch' });
        return false;
      } else if (regrakumart.test(this.$store.state.searchInfo)) {
        // rakumart 链接搜索进入详情页
        id = this.$store.state.searchInfo.match(reg3)[1];
        let regexty = /[?&]type=([^&#]+)/;
        let match = this.$store.state.searchInfo.match(regexty);
        // 获取type参数的值
        let typeValue = match ? match[1] : null;
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('用户通过rakumart链接搜索进入详情数量统计');
        }
        this.$fun.toCommodityDetails({ shop_type: typeValue, goods_id: id, type: 'rakumartlinkSearch' });
        return
      } else if (regrakumartbr.test(this.$store.state.searchInfo)) {
        // rakumart 链接搜索进入详情页
        id = this.$store.state.searchInfo.match(reg6)[1];
        let regexty = /[?&]type=([^&#]+)/;
        let match = this.$store.state.searchInfo.match(regexty);
        // 获取type参数的值
        let typeValue = match ? match[1] : null;
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('用户通过rakumart链接搜索进入详情数量统计');
        }
        this.$fun.toCommodityDetails({ shop_type: typeValue, goods_id: id, type: 'rakumartlinkSearch' });
        return
      }
      //进行判断
      else {
        if (this.$fun.isOfficialWeb()) {
          __bl.sum("用户发起关键词搜索数量统计");
        }
      }

      if (this.$store.state.searchInfo.trim().length !== 0) {
        if (this.$store.state.searchInfo == this.$route.query.keyword) {
          this.$router.go(0);
          return false;
        }
        if (!this.$store.state.searchInfo) {
          this.$message(this.$fanyi("请输入关键词搜索"));
          return false;
        }
        this.addKeyWord(this.$store.state.searchInfo);
        this.$fun.toPage(
          "/commoditySearch?keyword=" +
          this.$store.state.searchInfo +
          "&type=keywordSearch"
        );
      }
    },
    // 历史搜索
    Historicalsearch(val) {
      if (val.includes("rakumart-eu.oss-ap-northeast-1.aliyuncs.com")) {
        if (this.$fun.isOfficialWeb()) {
          __bl.sum("用户上传图片搜索数量统计");
        }
        this.$fun.toPage("/commoditySearch?type=imgSearch&imgUrl=" + val);
      } else {
        this.$store.state.searchInfo = val;
        this.search();
      }
    },
    //加入搜索历史
    addKeyWord(val) {
      if (val != "") {
        if (localStorage.getItem("clientUsFrontPCkeyWord") != null) {
          let keyWordList = JSON.parse(
            localStorage.getItem("clientUsFrontPCkeyWord")
          );
          if (keyWordList.indexOf(val) == -1) {
            if (keyWordList.length >= 10) {
              keyWordList.splice(0, 1);
              keyWordList.push(val);
              localStorage.setItem(
                "clientUsFrontPCkeyWord",
                JSON.stringify(keyWordList)
              );
            } else {
              keyWordList.push(val);
              localStorage.setItem(
                "clientUsFrontPCkeyWord",
                JSON.stringify(keyWordList)
              );
            }
          }
        } else {
          let keyWordList = [];
          keyWordList.push(val);
          localStorage.setItem(
            "clientUsFrontPCkeyWord",
            JSON.stringify(keyWordList)
          );
        }
      }
    },
    // 退出登录
    loginOut() {
      localStorage.removeItem("user_token");
      this.$store.commit("userData", null);
      this.$store.commit("setcartCount", 0);
      this.$store.commit("Sidebardatacleanup");
      localStorage.removeItem("clientUsFrontPCkeyWord");
      let requireAuth = this.$route.matched[0].meta.requireAuth;
      if (requireAuth) {
        this.$fun.toPage("/");
      }
    },
    befoUpload(file) {
      this.$store.commit("getshoplistpage", 1);
      const isJPG =
        ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;

      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传图片"));
        return isJPG;
      }

      this.pageLoading = this.$loading({
        lock: true,
        text: this.$fanyi("图片上传中"),
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, .7)",
      });
    },
    // 图片上传成功事件
    successup(res) {
      // 图搜

      this.uploadImagePic(res.data)

      // if (this.$fun.isOfficialWeb()) {
      //   __bl.sum("用户上传图片搜索数量统计");
      // }
      // if (this.$route.name == "CommoditySearch") {
      //   this.$fun.toPage("/commoditySearch?type=imgSearch&imgUrl=" + res.data);
      // } else {
      //   this.$fun.toPage("/commoditySearch?type=imgSearch&imgUrl=" + res.data);
      // }
    },
    // 图片上传成功事件
    uploadImagePic(val) {
      let that = this
      var img = new Image();
      img.src = val
      img.crossOrigin = 'Anonymous'; // 如果图片需要跨域访问，可以设置这个属性
      img.onload = function () {
        let width = img.width;
        let height = img.height;
        if (width > height) {
          if (width > 200) {
            height *= 200 / width;
            width = 200;
          }
        } else {
          if (height > 200) {
            width *= 200 / height;
            height = 200;
          }
        }
        var canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        var base64 = canvas.toDataURL('image/png', 0.5); // 可以改为image/jpeg，image/webp等其他格式
        let index = base64.indexOf('base64,');
        let base64String = base64.substr(index + 7, base64.length);
        let datas = {
          url: '/1/com.alibaba.fenxiao.crossborder/product.image.upload',
          uploadImageParam: JSON.stringify({
            imageBase64: base64String
          })
        }
        that.$api.uploadImagePic(datas).then(res => {


          // 图搜
          that.pageLoading.close();
          // 图搜统计
          that.addKeyWord(val + '&imageId=' + res.data.result);
          if (that.$fun.isOfficialWeb()) {
            __bl.sum("用户上传图片搜索数量统计");
          }
          if (that.$route.name == "CommoditySearch") {
            that.$fun.toPage("/commoditySearch?type=imgSearch&imgUrl=" + val + '&imageId=' + res.data.result);
          } else {
            that.$fun.toPage("/commoditySearch?type=imgSearch&imgUrl=" + val + '&imageId=' + res.data.result);
          }
        })
      };
    },
  },
};
</script>
<style lang="scss">
.popoversearchTip {
  margin-left: 120px;
  max-height: 636px;
  overflow-y: scroll;
  padding: 18px 24px;

  /* 隐藏水平和垂直滚动条 */
  &::-webkit-scrollbar {
    display: none;
  }

  .Historicalrecord {
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 15px;

    .Historical {
      min-width: 80px;

      font-size: 14px;
      color: #c6c6c6;
      padding-top: 4px;
      margin-right: 20px;
    }

    .record {
      display: flex;
      align-items: center;

      flex-wrap: wrap;

      .record-item {
        min-height: 28px;
        margin-bottom: 10px;


        &:hover {
          cursor: pointer;
        }

        padding: 5px 10px;

        display: flex;
        align-items: center;
        background-color: #f5f5f5;
        color: #999999;
        border-radius: 6px;
        margin-right: 10px;

        .el-icon-close {
          margin-left: 5px;

          font-size: 16px;
        }
      }
    }
  }

  .searchitem {
    h2 {
      font-size: 16px;
      color: #000000;
      font-weight: bold;
      margin-top: 15px;
    }

    .classification-item {
      display: flex;
      margin-right: 12px;
      flex-wrap: wrap;

      span {
        height: 30px;
        display: flex;
        align-items: center;
        font-size: 14px;
        justify-content: center;
        padding: 0 10px;
        border-radius: 6px;
        background-color: #f5f5f5;
        margin-top: 12px;
        margin-right: 12px;

        &:hover {
          cursor: pointer;
          background: linear-gradient(90deg, #ff7e3e, #ff5010, #fe4b0f);
          color: #fff;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped="scoped">
@import "../../css/mixin";

.u-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user_name {
  display: inline-block;
  width: 90px;
}

.flexAndCenter {
  display: flex;
  align-items: center;
}

.headMain {
  min-height: 186px;

  z-index: 9;

  #HomePageTop {
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0px;
    z-index: 999;

    // border-bottom: solid 1px rgba(61, 61, 61, 0.1);
    // box-shadow: 0px 29px 9px -29px rgba($color: #000000, $alpha: 0.07);
    .booosdj {
      background-color: black;

      .qiTa {
        width: $pageWidth;
        margin: 0 auto;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 59px;

        .zhiNan {
          padding-left: 6px;

          span {
            margin-right: 30px;
            font-size: 13px;

            font-weight: 500;
            color: white;
            cursor: pointer;

            &:hover {
              .dropDownBox {
                height: 95px;
              }
            }

            position: relative;

            .dropDownBox {
              transition: 0.3s;
              position: absolute;
              top: 30px;
              left: 50%;
              transform: translate(-50%, 0);
              width: 120px;
              height: 0;
              overflow: hidden;
              z-index: 23;
              background: #ffffff;
              box-shadow: 0px 0px 10px 0px rgba(136, 136, 136, 0.2);
              border-radius: 10px;

              >span {
                display: block;
                cursor: pointer;
                text-align: center;
                line-height: 32px;
                width: 120px;
                height: 32px;
                font-size: 16px;

                font-weight: 400;

                &:hover {
                  color: $homePageSubjectColor;
                  background: #e2e5ff;
                }
              }
            }
          }
        }

        .huiLv {
          border-radius: 4px;

          font-size: 13px;
          font-weight: 500;
          color: white;
          text-align: center;

          .numd {
            font-weight: bolder;
          }
        }

        .China-ukhandover {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 20px;

          // margin-right: 15px;
        }

        .active {
          color: #ff730b;
        }
      }
    }

    >.fgx {
      width: 100%;
      height: 1px;
      background-color: rgba(61, 61, 61, 0.1);
    }

    >#HomePageTopCon {
      width: $pageWidth;
      margin: 0 auto;
      height: 107.28px;
      min-height: 65px;
      display: flex;
      align-items: center;
      transition: linear 0.2s;
      display: flex;

      >div {
        display: flex;
        align-items: center;

        // logo
        &.logoBox {
          justify-content: flex-start;
          width: 205.63px;
          height: 66.28px;
          padding: 10px;

          #logo {
            transition: 0.5s;
            width: 100%;
            min-width: 77.5px;
            cursor: pointer;
          }
        }

        &.searchBoxDiv {
          width: 720px;
          height: 58px;
          justify-content: flex-start;
          display: flex;
          border: 2px solid #ff730b;
          border-radius: 4px;
          margin: 0 auto 0;

          /deep/.el-select {
            flex: 0 0 185px;

            .el-input__inner {
              border: none;
              height: 54px !important;
              font-size: 14px;
              font-weight: 400 !important;

              &::placeholder {
                font-size: 14px;
                color: #999 !important;
              }
            }

            .el-input__icon {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .fg {
            height: 20px;
            width: 1px;
            background-color: #6d6d6d;
          }

          /deep/.el-input {
            flex: 1;

            .el-input__inner {
              border: none;
              font-size: 13px;
              height: 54px !important;
              padding-left: 15px;
              font-weight: 500;
              color: black;
            }
          }

          /deep/.el-upload {
            width: 54px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;

            img {
              width: 30px;
            }
          }

          .searchInputBoxFg {
            width: 1px;
            height: 16px;
            background-color: #999;
          }

          /deep/.el-button {
            width: 64px;
            height: 56px;
            border: none;
            background: white;
            border-radius: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ff730b;
            background: #ff730b;

            img {
              width: 30px;
              height: 30px;
            }
          }
        }

        &.operate {
          .userDetail {
            width: 200px;

            $userImgSize: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            position: relative;

            &.notLogin {
              text-align: right;
              width: 234.56px;
              justify-content: flex-end;

              img {
                width: 30px;
                height: 30px;
                margin-right: 5.5px;
              }
            }

            &:hover {
              .dropDownBox {
                height: 64px;
              }
            }

            .dropDownBox {
              transition: 0.3s;
              position: absolute;
              top: 100%;
              left: 49px;
              width: 120px;
              height: 0;
              overflow: hidden;
              background: #ffffff;
              box-shadow: 0px 0px 10px 0px rgba(136, 136, 136, 0.2);
              border-radius: 10px;
              z-index: 777;

              >span {
                display: block;
                cursor: pointer;
                text-align: center;
                line-height: 32px;
                width: 120px;
                height: 32px;
                font-size: 16px;

                font-weight: 400;

                &:hover {
                  color: $homePageSubjectColor;
                  background: #e2e5ff;
                }
              }
            }

            // 用户头像大小
            $userHeadImgSize: 22px;

            .userHeadImg {
              border-radius: 50%;
              width: $userHeadImgSize;
              height: $userHeadImgSize;
              margin-right: 6px;
            }

            .moRenTouXiang {
              width: $userHeadImgSize;
              height: $userHeadImgSize;
              margin-right: 5px;
            }

            span {
              font-size: 14px;

              font-weight: 500;
              color: #000000;

              >* {
                text-decoration: underline;
              }

              .fg {
                text-decoration: unset;
              }

              &:nth-child(3) a {
                color: $homePageSubjectColor;
              }
            }

            .userLoginInfoBox {
              .toUserIndex {
                cursor: pointer;
                font-size: 13px;
                text-decoration: unset !important;

                &:hover {
                  color: $homePageSubjectColor;
                }
              }

              .routerBtn {
                cursor: pointer;
                font-size: 13px;

                &:hover {
                  color: $homePageSubjectColor;
                }
              }
            }
          }

          .huilv {
            margin-right: 20px;
          }

          .cartBtn {
            button {
              display: flex;
              border-radius: 33px;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              // padding: 10px 15px;
              margin-left: 15px;

              .carticon {
                position: relative;

                img {
                  margin-right: 5.5px;
                }

                span {
                  position: absolute;
                  right: 0;
                  top: -5px;
                  min-width: 20px;
                  height: 20px;
                  background: #ff730b;
                  line-height: 20px;
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #ffffff;
                  text-align: center;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }
    }

    >.hr {
      border-bottom: #efefef solid 1px;
    }
  }
}

#classBox {
  display: block;
}

.zhanWeiDiv {
  height: 80px;
}
</style>
