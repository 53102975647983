<template>
  <div class="zhanwei">
    <div class="classificationOfGoods" @mouseleave="showDetails = false">
      <!-- 分类大盒子 -->
      <div class="bigClassify-box">
        <div class="zz">
          <button @click="trunCon('左')" v-if="lists.length > 11">
            <i class="el-icon-arrow-left"></i>
          </button>
        </div>
        <div class="classify" @mouseleave="showDetails = false">
          <div id="classifyCon" class="classifyCon" :style="{ left: index * 129 + 'px' }">
            <div class="item" v-for="(item, index) in lists" :key="index" @mouseover="
              mouseInter(index);
            setHeightOpen();
            " @mouseleave="setComBodyHeight()" @click="
  setComBodyHeight();
$fun.toPage(
  '/commoditySearch?keyword=' +
  item.translation_name +
  '&CN=' +
  $fun.toCode(item.chinese_name)
);
">
              <div class="icon">
                <img :src="item.default_icon" alt="" />
                <img :src="item.trigger_icon" alt="" class="trigger_icon" />
              </div>

              <p :title="item.translation_name">
                {{ item.translation_name }}
              </p>
              <div class="icon-top">
                <i class="el-icon-caret-top"></i>
              </div>
              <!--  小分类 -->
              <transition name="el-zoom-in-top">
                <div class="classify-box" :class="{ rightBox: index > 5, leftBox: index < 5 }" v-show="item.show"
                  @mouseleave="setComBodyHeight">
                  <div class="classifyList">
                    <div class="items" v-for="(items, indexs) in item.childs" :key="indexs" @click.stop="
                      setComBodyHeight();
                    $fun.toPage(
                      '/commoditySearch?keyword=' +
                      items.translation_name +
                      '&CN=' +
                      $fun.toCode(items.chinese_name)
                    );
                    ">
                      {{ items.translation_name }}
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="zz">
          <button @click="trunCon('右')" v-if="lists.length > 11">
            <i class="el-icon-arrow-right"></i>
          </button>
        </div>
      </div>
      <!-- 遮罩层 -->
      <transition name="el-fade-in">
        <div class="mask" v-show="showDetails" id="mask"></div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDetails: false,
      form: {
        type: 1,
      },
      index: 0,
      // 所有数据
      lists: [{}],
      // 二级菜单展示的数据
      showData: [],
    };
  },
  components: {},
  computed: {
    // lists() {
    //   return this.$store.state.goodsList;
    // },
  },
  created() {
    this.getData();
  },
  methods: {
    setHeightOpen() {
      document.getElementsByClassName("mask")[0].style.display = "block";
      document.getElementsByClassName("classificationOfGoods")[0].style.height =
        "630px";
    },
    setComBodyHeight() {
      document.getElementsByClassName("mask")[0].style.display = "none";
      document.getElementsByClassName("classificationOfGoods")[0].style.height =
        "100px";
    },
    trunCon(type) {
      if (type == "左") {
        if (this.index < 0) {
          this.index++;
        }
      } else if (type == "右") {
        if (this.index > 11 - this.lists.length) {
          this.index--;
        }
      }
    },
    // 获取搜索框分类数据和首页分类数据
    getData() {
      this.$api
        .getShopGroup(this.form)
        .then((result) => {
          this.lists = result.data;
          this.$store.commit("getgoodsList", result.data);
          this.allListShow();
        })
        .catch((err) => { });
    },
    //全部隐藏
    allListShow() {
      this.lists.forEach((item) => {
        item.show = false;
      });
    },
    mouseInter(index) {
      this.showDetails = true;
      this.allListShow();
      this.lists[index].show = true;
      this.$forceUpdate();
    },
    // getData() {
    //   this.$api
    //     .getShopGroup(this.form)
    //     .then((result) => {
    //   

    //       this.lists = result.data;
    //       this.allListShow();
    //     })
    //     .catch((err) => {});
    // },
    // //全部隐藏
    allListShow() {
      this.lists.forEach((item) => {
        item.show = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
@import "../../css/mixin";

.zhanwei {
  height: 89px;
}

.classificationOfGoods {
  width: 100%;
  position: absolute;
  overflow-x: hidden;
  height: 100px;
  transition: 0.1s;
  margin-bottom: 50px;

  &::-webkit-scrollbar {
    width: 0px;
  }

  .bigClassify-box {
    position: relative;
    z-index: 9;
    background-color: white;
    display: flex;
    // justify-content: center;
    align-items: center;

    .zz {
      background-color: white;
      flex: 1;
      position: relative;

      height: 89px;
      z-index: 233;

      &:first-child {
        text-align: right;
      }

      button {
        height: 100%;
        font-weight: bold;
        background-color: transparent;

        &:hover {
          i {
            color: #1e2997;
          }
        }

        i {
          font-size: 20px;
        }
      }
    }

    .classify {
      width: 1400px;

      height: 89px;
      display: flex;
      justify-content: center;
      position: relative;

      .classifyCon {
        position: absolute;
        left: 0;

        transition: 0.3s;
        height: 89px;
        display: flex;
        justify-content: center;
      }

      .item {
        width: 104px;
        box-sizing: content-box;
        padding: 0 12.5px;
        cursor: pointer;
        position: relative;

        &:nth-last-child(1) {
          margin-right: 0;
        }

        &:nth-child(1) {
          margin-left: 0;
        }

        &:hover img {
          display: none;
        }

        &:hover .icon .trigger_icon {
          display: block;
        }

        &:hover .classify-box {
          display: block;
        }

        &:hover .icon-top {
          display: block;
        }

        .icon {
          position: relative;
          width: 8px;
          height: 28px;
          border-radius: 50%;
          background-color: #fff;
          margin: 25px auto 6px;

          img {
            width: 28px;
            height: 28px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .trigger_icon {
            display: none;
          }
        }

        p {
          display: block;
          width: 100%;

          overflow: hidden;
          height: 15px;
          font-size: 12px;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
        }

        .icon-top {
          font-size: 25px;
          color: #000;
          display: none;
          text-align: center;
          transform: translate(0, 1px);
        }

        .classify-box {
          position: absolute;
          top: 89px;
          height: 0;
          overflow: hidden;
          background-color: #fff;
          z-index: 10;
          transition: 0.3s;
          width: 470px;
          height: 500px;
          padding: 10px;
          display: none;

          .classifyList {
            overflow-y: auto;
            height: 100%;

            .items {
              float: left;
              width: 215px;
              height: 28px;
              color: #000;
              font-size: 12px;
              padding: 0 5px;
              cursor: pointer;
              display: flex;
              align-items: center;

              &:hover {
                background-color: $homePageSubjectColor;
                border-radius: 5px;
                color: #fff;
              }
            }
          }
        }

        .leftBox {
          left: 0;
        }

        .rightBox {
          right: 0;
        }
      }
    }
  }
}

#mask {
  position: fixed;
  top: 220px;
  left: 0;
  width: 10000px;
  height: 10000px;
  z-index: 8;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
